import {put, all, call, takeLatest, takeEvery} from 'redux-saga/effects'
import customAxiosPost from '../../utils/customAxiosPost'

import {
    LOAD_BRAND,
    LOAD_CITY,
    putBrand,
    putCity,
    putManager,
    LOAD_MANAGER,
    LOAD_USERS,
    putUsers,
    LOAD_NEW_APL,
    putNewApl,
    LOAD_REMAINDERS,
    putRemainders,
    LOAD_CODE_SELLER,
    putCodesSellers,
    LOAD_POSITION,
    putPosition,
    putDataForTableOrder,
    LOAD_DATA_FOR_TABLE_ORDER,
    putFilters,
    LOAD_FILTERS,
    LOAD_FILTERED_DATA,
    putFilteredData
} from '../actions/actions'


//BRAND List start
function* workerBrand({payload}) {
    const data = yield payload
    yield put(putBrand(data)) 
}

function* watchBrand() {
    yield takeLatest(LOAD_BRAND, workerBrand)
}
// Brand List end
//CITIES List start
function* workerCity({payload}) {
    const data = yield payload
    yield put(putCity(data)) 
}

function* watchCity() {
    yield takeLatest(LOAD_CITY, workerCity)
}
// CITIES List end

//CITIES List start
function* workerManager({payload}) {
    const data = yield payload
    yield put(putManager(data)) 
}

function* watchManager() {
    yield takeLatest(LOAD_MANAGER, workerManager)
}
// USERS List end
//CITIES List start
function* workerUsers({payload}) {
    const data = yield payload
    yield put(putUsers(data)) 
}

function* watchUsers() {
    yield takeLatest(LOAD_USERS, workerUsers)
}
// USERS List end
//APL List start
function* workerApl({payload}) {
    const data = yield payload
    yield put(putNewApl(data)) 
}

function* watchApl() {
    yield takeLatest(LOAD_NEW_APL, workerApl)
}
// APL List end
//Remainders List start
function* workerRemainders({payload}) {
    const data = yield payload
    yield put(putRemainders(data)) 
}

function* watchRemainders() {
    yield takeLatest(LOAD_REMAINDERS, workerRemainders)
}
// Remainders List end
//Remainders List start
function* workerCodeSeller({payload}) {
    const data = yield payload
    yield put(putCodesSellers(data)) 
}

function* watchCodeSeller() {
    yield takeLatest(LOAD_CODE_SELLER, workerCodeSeller)
}
// Remainders List end

//PositionList Start
function fetchPosition() {
  return customAxiosPost('/groupList')
  .then(res => res.data)
}
function* workerPosition() {
  const data = yield call(fetchPosition)
  yield put(putPosition(data))
}
function* watchPosition(){
  yield takeLatest(LOAD_POSITION, workerPosition)
}
//PositionList End

//Data for table order start
function* workerDataForTableOrder({payload}) {
  const data = yield payload
  yield put(putDataForTableOrder(data)) 
}

function* watchDataForTableOrder() {
  yield takeLatest(LOAD_DATA_FOR_TABLE_ORDER, workerDataForTableOrder)
}
// Data for table order end
//FILTERS start
function* workerFilters({payload}) {
  const data = yield payload
  yield put(putFilters(data)) 
}

function* watchFilters() {
  yield takeEvery(LOAD_FILTERS, workerFilters)
}
// FILTERS order end

//FILTERED DATA start
function* workerFilteredData({payload}) {
  const data = yield payload
  yield put(putFilteredData(data)) 
}

function* watchFilteredData() {
  yield takeEvery(LOAD_FILTERED_DATA, workerFilteredData)
}
// FILTERED DATA order end



export default function* rootSaga() {
    yield all([
        watchBrand(),
        watchCity(),
        watchManager(),
        watchUsers(),
        watchApl(),
        watchRemainders(),
        watchCodeSeller(),
        watchPosition(),
        watchDataForTableOrder(),
        watchFilters(),
        watchFilteredData()
    ])
}