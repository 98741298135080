export const LOAD_BRAND = 'LOAD_BRAND'
export const PUT_BRAND = 'PUT_BRAND'
export const LOAD_CITY = 'LOAD_CITY'
export const PUT_CITY = 'PUT_CITY'
export const LOAD_MANAGER = 'LOAD_MANAGER'
export const PUT_MANAGER = 'PUT_MANAGER'
export const LOAD_USERS = 'LOAD_USERS'
export const PUT_USERS = 'PUT_USERS'
export const LOAD_NEW_APL = 'LOAD_NEW_APL'
export const PUT_NEW_APL = 'PUT_NEW_APL'
export const LOAD_REMAINDERS = 'LOAD_REMAINDERS'
export const PUT_REMAINDERS = 'PUT_REMAINDERS'
export const LOAD_CODE_SELLER = 'LOAD_CODE_SELLER'
export const PUT_CODE_SELLER = 'PUT_CODE_SELLER'
export const LOAD_POSITION = 'LOAD_POSITION'
export const PUT_POSITION = 'PUT_POSITION'
export const LOAD_DATA_FOR_TABLE_ORDER = 'LOAD_DATA_FOR_TABLE_ORDER'
export const PUT_DATA_FOR_TABLE_ORDER = 'PUT_DATA_FOR_TABLE_ORDER'
export const LOAD_FILTERS = 'LOAD_FILTERS'
export const PUT_FILTERS = 'PUT_FILTERS'
export const LOAD_FILTERED_DATA = 'LOAD_FILTERED_DATA'
export const PUT_FILTERED_DATA = 'PUT_FILTERED_DATA'
export const LOAD_DELETE_FILTER = 'LOAD_DELETE_FILTER'
export const PUT_DELETE_FILTER = 'PUT_DELETE_FILTER'

export const loadBrand = (state) => {
  return {
      type: LOAD_BRAND,
      payload: state
  }
}
export const putBrand = (brandFromFirebase) => {
  return{
      type: PUT_BRAND,
      payload: brandFromFirebase
  }
}
export const loadCity = (state) => {
  return {
      type: LOAD_CITY,
      payload: state
  }
}

export const putCity = (cityFromFirebase) => {
  return{
      type: PUT_CITY,
      payload: cityFromFirebase
  }
}
export const loadManager = (state) => {
  return {
      type: LOAD_MANAGER,
      payload: state
  }
}

export const putManager = (managerFromFirebase) => {
  return{
      type: PUT_MANAGER,
      payload: managerFromFirebase
  }
}
export const loadUsers = (state) => {
  return {
      type: LOAD_USERS,
      payload: state
  }
}

export const putUsers = (users) => {
  return{
      type: PUT_USERS,
      payload: users
  }
}
export const loadnewApl = (state) => {
  return {
      type: LOAD_NEW_APL,
      payload: state
  }
}

export const putNewApl = (apl) => {
  return{
      type: PUT_NEW_APL,
      payload: apl
  }
}
export const loadRemainders = (state) => {
  return {
      type: LOAD_REMAINDERS,
      payload: state
  }
}

export const putRemainders = (rem) => {
  return{
      type: PUT_REMAINDERS,
      payload: rem
  }
}
export const loadCodesSellers = (state) => {
  return {
      type: LOAD_CODE_SELLER,
      payload: state
  }
}

export const putCodesSellers = (rem) => {
  return{
      type: PUT_CODE_SELLER,
      payload: rem
  }
}
export const loadPosition = (state) => {
  return {
      type: LOAD_POSITION,
      payload: state
  }
}

export const putPosition = (list) => {
  return{
      type: PUT_POSITION,
      payload: list
  }
}
export const loadDataForTableOrder = (state) => {
  return {
      type: LOAD_DATA_FOR_TABLE_ORDER,
      payload: state
  }
}

export const putDataForTableOrder = (list) => {
  return{
      type: PUT_DATA_FOR_TABLE_ORDER,
      payload: list
  }
}
export const loadFilters = (state) => {
  return {
      type: LOAD_FILTERS,
      payload: state
  }
}

export const putFilters = (list) => {
  return{
      type: PUT_FILTERS,
      payload: list
  }
}
export const loadFilteredData = (state) => {
  return {
      type: LOAD_FILTERED_DATA,
      payload: state
  }
}

export const putFilteredData = (list) => {
  return{
      type: PUT_FILTERED_DATA,
      payload: list
  }
}