import React, { useEffect, useState, createContext } from "react";
import firebase from "firebase/app";
import 'firebase/auth'
import 'firebase/database'

import Loading from '../components/Loading/Loading'
export const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
  const [pending, setPending] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  const db = firebase.database()
  const users = db.ref('users')

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user === null) {
        setPending(false)
      } else {
        users.child(user.uid).child('admin')
          .once('value', elem => setIsAdmin(elem.val()))
          .finally(() => setPending(false))
      }
    });
  }, []);

  if (pending) {
    return <Loading />
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser: isAdmin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};



// import React, { useEffect, useState } from "react";
// import firebase from "firebase/app";
// import 'firebase/auth'

// import Loading from '../components/Loading/Loading'

// export const AuthContext = React.createContext();



// export const AuthProvider = ({ children }) => {
//   const [currentUser, setCurrentUser] = useState(null);
//   const [pending, setPending] = useState(true);

//   useEffect(() => {
//     firebase.auth().onAuthStateChanged((user) => {
//       setCurrentUser(user)
//       setPending(false)
//     });
//   }, []);

//   if (pending) {
//     return <Loading />
//   }

//   return (
//     <AuthContext.Provider
//       value={{
//         currentUser
//       }}
//     >
//       {children}
//     </AuthContext.Provider>
//   );
// };
