import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'

const customAxiosPost = async (link, body) => {
  const token = await firebase.auth().currentUser.getIdToken()
  if(token === null){
    window.location.reload()
  } 
  return axios.post(link, {...body, Authorization: token})
}
export default customAxiosPost