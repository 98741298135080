import React from 'react'
import { Dimmer, Loader, Container } from 'semantic-ui-react'

import 'semantic-ui-css/semantic.min.css'

// const styleLink = document.createElement("link");
// styleLink.rel = "stylesheet";
// styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
// document.head.appendChild(styleLink);

const Loading = () => (
    <Container style={{ margin: 20 }}>
        <div>
            <Dimmer active inverted>
                <Loader size='large'>Loading</Loader>
            </Dimmer>
        </div>
    </Container>
)

export default Loading