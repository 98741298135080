import React, {lazy, Suspense} from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./auth/Auth";
import PrivateRoute from "./components/PrivateRoute";
import axios from 'axios'

import Loading from './components/Loading/Loading'

// const SetPassword = lazy(() => import('./components/SetPassword/SetPassword'))
const NotFound = lazy(() => import('./components/NotFound'))
const Login = lazy(() => import('./components/Login/Login'))
const Main = lazy(() => import('./components/Main'))
const Brands = lazy(() => import('./components/Brands'))
const Affiliate = lazy(() => import('./components/Affiliate'))
const Managers = lazy(() => import('./components/Managers'))
const Users = lazy(() => import('./components/UserPage/Users'))
const EditUser = lazy(() => import('./components/EditUser/EditUser'))
const Blanks = lazy(() => import('./components/Blanks'))
const Aplications = lazy(() => import('./components/Aplications'))
const AddUser = lazy(()=> import('./components/UserPage/AddUser'))
const SendSms = lazy(()=> import('./components/SendSms'))
const Remainders = lazy(()=> import('./components/Remainders'))
const CorporateClient = lazy(()=> import('./components/CorporateClient'))
const Sellers = lazy(()=> import('./components/Sellers'))
const DataForTableOrder = lazy(()=> import('./components/DataForTableOrder'))


const Hello = () => <h1>hello</h1>

function App() {
    axios.defaults.baseURL = 'https://europe-west3-order-lens.cloudfunctions.net/order'
    // axios.defaults.baseURL = 'http://localhost:5000/order-lens/europe-west3/order';

  return (
    <AuthProvider>
      <Router>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Main>
              <PrivateRoute exact path="/" component={Hello} />
              <PrivateRoute exact path="/brands" component={Brands} />
              <PrivateRoute exact path="/reguser" component={AddUser} />
              <PrivateRoute exact path="/blanks" component={Blanks} />
              <PrivateRoute exact path="/Affiliate" component={Affiliate} />
              <PrivateRoute exact path="/managers" component={Managers} />
              <PrivateRoute exact path="/aplications" component={Aplications} />
              <PrivateRoute exact path="/sms" component={SendSms} />
              <PrivateRoute exact path="/remainders" component={Remainders} />
              <PrivateRoute exact path="/sellers" component={Sellers} />
              <PrivateRoute exact path="/addCorporateClient" component={CorporateClient} />
              <PrivateRoute exact path="/add-users" component={Users} />
              <PrivateRoute exact path="/data-for-table-order" component={DataForTableOrder} />
              <PrivateRoute exact path="/edit-users/:id" component={EditUser} />
              <PrivateRoute exact path="/aplications/:id/:name/:phone" component={AddUser} />
            </Main>
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
    </AuthProvider>
  );
}

export default App;
