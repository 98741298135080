import {
        PUT_BRAND,
        PUT_CITY,
        PUT_MANAGER,
        PUT_USERS,
        PUT_NEW_APL,
        PUT_REMAINDERS,
        PUT_CODE_SELLER,
        PUT_POSITION,
        PUT_DATA_FOR_TABLE_ORDER,
        PUT_FILTERS,
        PUT_FILTERED_DATA,
} from '../actions/actions'

const initialState = {
    brands: [],
    cities: [],
    managers: [],
    users: [],
    aplications: [],
    remainders: [],
    codesSellers: [],
    positions: [],
    dataForTableOrder: [],
    filters: {},
    filteredUsersList:[]
}

export const data = (state = initialState, {type, payload}) => {
    switch (type) {
        case PUT_BRAND:
            return{
                ...state,
                brands: payload
            }
        case PUT_CITY:
            return{
                ...state,
                cities: payload
            }
        case PUT_MANAGER:
            return{
                ...state,
                managers: payload
            }
        case PUT_USERS:
            return{
                ...state,
                users: payload
            }
        case PUT_NEW_APL:
            return{
                ...state,
                aplications: Object.values(payload)
            }
        case PUT_REMAINDERS:
            return{
                ...state,
                remainders: payload
            }
        case PUT_CODE_SELLER:
            return{
                ...state,
                codesSellers: payload
            }
        case PUT_POSITION:
          return{
              ...state,
              positions: payload
          }
        case PUT_DATA_FOR_TABLE_ORDER:
          return{
              ...state,
              dataForTableOrder: payload
          }
        case PUT_FILTERS:
          return{
              ...state,
              filters: {
                ...state.filters,
                [payload.type]: payload.data
              }
          }
        case PUT_FILTERED_DATA:
          return{
              ...state,
              filteredUsersList: payload
          }
        default:
            return state
    }
}